<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="true"
    :onExport="onExport"
  >
    <!--
    搜索：商品名称，会员信息，手机号，会内职务，
    分销订单数量筛选（0—10，10—30，30—50，50—100，100—200，200—300，300以上），
    分销订单金额和佣金总金额（从0开始，每50为一个阶段，超过500的用500以上）
    -->
    <el-form
      :model="form"
      :rules="rules"
      ref="templateForm"
      label-width="120px"
      label-position="right"
      size="medium"
      class="filter-form grid"
      style="margin-left: -20px"
    >
      <!--      <div class="filter-item">-->
      <el-form-item label="商品名称：" prop="goods_name">
        <el-input v-model="form.goods_name" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="会员信息：" prop="user_name">
        <el-input v-model="form.user_name" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="会内职务：" prop="positionIds">
        <el-cascader
          v-model="form.positionIds"
          :options="OrganizationList"
          :props="props"
          placeholder="架构/会内职务"
          filterable
          clearable
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="分销订单数量：" prop="min_orders_count">
        <el-select v-model="orderNumRange" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in [
              '0—10',
              '10—30',
              '30—50',
              '50—100',
              '100—200',
              '200—300',
              '300以上',
            ]"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <input type="hidden" v-model="filterForm.min_orders_count" />
      </el-form-item>
      <el-form-item style="display: none" label="分销订单数量：" prop="max_orders_count">
        <input type="hidden" v-model="filterForm.max_orders_count" />
      </el-form-item>
      <el-form-item style="display: none" label="分销订单金额：" prop="max_orders_price">
        <input type="hidden" v-model="filterForm.max_orders_price" />
      </el-form-item>
      <el-form-item style="display: none" label="佣金总金额：" prop="max_retail_price">
        <input type="hidden" v-model="filterForm.max_retail_price" />
      </el-form-item>
      <el-form-item label="分销订单金额：" prop="min_orders_price">
        <input type="hidden" v-model="filterForm.min_orders_price" />
        <el-select v-model="orderPriceRange" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in moneyOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="佣金总金额：" prop="min_retail_price">
        <input type="hidden" v-model="filterForm.min_retail_price" />
        <el-select v-model="retailPriceRange" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in moneyOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!--      </div>-->
      <!--      <div class="filter-item">-->

      <!--      </div>-->
      <!--      <div class="filter-item">-->
<!--      <DatePeriodPicker-->
<!--        label="创建时间："-->
<!--        start-prop="start_time"-->
<!--        :start-time.sync="form.start_time"-->
<!--        end-prop="end_time"-->
<!--        :end-time.sync="form.end_time"-->
<!--        style="grid-column: 1 / span 2"-->
<!--      />-->
      <!--      </div>-->
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { parentToList } from '../../organization-admin/api/org-frame/frame-list'

export default {
  name: 'distribution-filter',
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    isGov: Boolean,
    exportApi: Function,
  },
  data() {
    return {
      rules: {},
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
      },
      // 架构选择列表
      OrganizationList: [],
      moneyOptions: [],
    }
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
    orderNumRange: {
      get() {
        if (this.form.min_orders_count === -1) return ''
        return `${this.form.min_orders_count}—${this.form.max_orders_count}`
      },
      set(val) {
        if (val) {
          const [min, max] = val.split('—');
          this.form.min_orders_count = min
          this.form.max_orders_count = max
        } else {
          this.form.min_orders_count = -1
          this.form.max_orders_count = -1
        }
      }
    },
    orderPriceRange: {
      get() {
        if (this.form.min_orders_price === -1) return ''
        return `${this.form.min_orders_price}—${this.form.max_orders_price}`
      },
      set(val) {
        if (val) {
          const [min, max] = val.split('—');
          this.form.min_orders_price = min
          this.form.max_orders_price = max
        } else {
          this.form.min_orders_price = -1
          this.form.max_orders_price = -1
        }
      }
    },
    retailPriceRange: {
      get() {
        if (this.form.min_retail_price === -1) return ''
        return `${this.form.min_retail_price}—${this.form.max_retail_price}`
      },
      set(val) {
        if (val) {
          const [min, max] = val.split('—');
          this.form.min_retail_price = min
          this.form.max_retail_price = max
        } else {
          this.form.min_retail_price = -1
          this.form.max_retail_price = -1
        }
      }
    },
  },
  created() {
    this.getParentToList()

    // step 为 50
    const step = 50
    this.moneyOptions = Array.from(
      Array(10),
      (v, k) => `${k * step}—${(k + 1) * step}`
    )
    this.moneyOptions.push('500以上')
  },
  methods: {
    // 获取架构列表
    getParentToList() {
      parentToList({ crr_level: -1, is_show_all: 1 })
        .then((res) => {
          const { data } = res
          data.map((item) => {
            item.children = []
          })
          this.OrganizationList = data
        })
        .catch((err) => {})
    },
    onExport() {
      const postData = { ...this.form }
      // return exportRetailGoods(postData)
      this.$alert('开发中')
    },
    //重置表单
    resetForm() {
      this.$refs['templateForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
  },
}
</script>

<style lang="scss" scoped>
.price-box {
  div {
    margin: 0 5px;
  }
}

.filter-form.grid {
  grid-template-columns: 300px 300px 340px;
}
</style>
