import api from '@/base/utils/request'

export const userDistributionOrders = (data) => {
  return api({url:'/admin/retail/records/ordersList', method: 'post', data})
}

export const getRetailRecords = (data) => {
  return api({url:'/admin/retail/records/index', method: 'post', data})
}

export const getWithdraws = (data) => {
  return api({url:'/admin/retail/records/withdrawList', method: 'post', data})
}