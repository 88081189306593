var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('list-layout',{ref:"distributionList",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"on-fetch":_vm.getList},on:{"command":_vm.onCommand},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('distribution-filter',{attrs:{"filter-form":_vm.filterForm,"upload-filter":_vm.ok}})]},proxy:true},{key:"item_user.id",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.id))]}},{key:"item_user.nickname",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.nickname))]}},{key:"item_user.phone",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.phone))]}},{key:"item_user.title_name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.user.title_name || '--'))]}}])}),_c('el-dialog',{staticClass:"dialog-vertical",attrs:{"title":"订单详情","width":"1200px","visible":_vm.showTransfer,"append-to-body":"","close-on-click-modal":"","close-on-press-escape":""},on:{"update:visible":function($event){_vm.showTransfer=$event}}},[_c('ListLayout',{ref:"ordersList",attrs:{"on-fetch":_vm.onFetchOrders,"filter-form":_vm.ordersForm,"thead":_vm.ordersHead,"table-height":"60vh"},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var pageData = ref.pageData;
var idList = ref.idList;
return [_c('SourceScreen',{attrs:{"openType":[],"openCategory":[],"openStatus":_vm.openStatus,"filterForm":_vm.ordersForm,"selectArr":idList,"page":pageData.current_page,"uploadFilter":_vm.ordersOk}})]}},{key:"item_image_name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex"},[_c('list-image',{staticStyle:{"flex-shrink":"0"},attrs:{"src":row.image,"width":100}}),_c('p',{staticStyle:{"white-space":"normal","margin-left":"10px"}},[_vm._v(_vm._s(row.name))])],1)]}},{key:"item_price_amount",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-col"},[_c('p',{staticStyle:{"white-space":"normal"}},[_vm._v(_vm._s(row.price.toFixed(2))+"元")]),_c('p',{staticStyle:{"white-space":"normal"}},[_vm._v("x"+_vm._s(row.amount))])])]}},{key:"item_total_price",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-col"},[_c('p',{staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(row.total_price.toFixed(2))+"元 ")])])]}},{key:"item_retail_price",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-col"},[_c('p',{staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(row.retail_price.toFixed(2))+"元 ")])])]}}])})],1),_c('el-dialog',{staticClass:"dialog-vertical",attrs:{"title":"提现记录","width":"800px","visible":_vm.showWithdraw,"append-to-body":"","close-on-click-modal":"","close-on-press-escape":""},on:{"update:visible":function($event){_vm.showWithdraw=$event}}},[_c('ListLayout',{ref:"withdrawList",attrs:{"on-fetch":_vm.onFetchWithdraw,"filter-form":_vm.withdrawForm,"thead":_vm.withdrawHead,"table-height":"40vh"},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var pageData = ref.pageData;
var idList = ref.idList;
return [_c('UserWithdrawRecordFilter',{attrs:{"openStatus":_vm.openStatus2,"filterForm":_vm.withdrawForm,"selectArr":idList,"page":pageData.current_page,"uploadFilter":_vm.withdrawsOk}})]}},{key:"item_price",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-col"},[_c('p',{staticStyle:{"white-space":"normal"}},[_vm._v(_vm._s(row.price.toFixed(2))+"元")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }