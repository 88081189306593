<template>
  <LayoutFilter :onFilter="ok" :toggleCollapseEnable="false"
                :onReset="resetForm" :onExport="null">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from grid" @submit.native.prevent>

        <el-form-item label="提现编号：" prop="record_number">
          <el-input v-model="form.record_number" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label="提现状态：" prop="status">
          <el-select v-model="form.status" placeholder="">
            <el-option label="全部" :value="-1"></el-option>
            <el-option v-for="item in openStatus" :label="item.name" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <DatePeriodPicker label="提现时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" style="grid-column: 1 / span 2"
        />
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    selectArr: {
      type: Array,
      default: () => [],
    },
    openStatus: {
      type: Array,
      default: () => [],
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
    // onExport: {
    //   type: Function,
    // },
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出
    onExport() {
      // const postData = { ...this.form, ids: this.selectArr, page: this.page };
      // return
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
