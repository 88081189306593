<template>
  <div>
    <!--
      1.提现审批状态为：全部，待审核，通过，不通过

      2.列表可导出

      3.详情为：会员id，头像，会员名称，手机号，会内职务，提现金额，状态，提现时间，操作为：通过/不通过，不通过需填写原因
    -->
    <list-layout
      ref="distributionList"
      :filter-form="filterForm"
      :thead="thead"
      :on-fetch="getList"
      @command="onCommand"
    >
      <template #filter>
        <distribution-filter
          :filter-form="filterForm"
          :upload-filter="ok"
        ></distribution-filter>
      </template>
      <template #item_user.id="{ row }">{{ row.user.id }}</template>
      <template #item_user.nickname="{ row }">{{ row.user.nickname }}</template>
      <template #item_user.phone="{ row }">{{ row.user.phone }}</template>
      <template #item_user.title_name="{ row }">{{
        row.user.title_name || '--'
      }}</template>
    </list-layout>
    <el-dialog
      title="订单详情"
      width="1200px"
      class="dialog-vertical"
      :visible.sync="showTransfer"
      append-to-body
      close-on-click-modal
      close-on-press-escape
    >
      <ListLayout
        ref="ordersList"
        :on-fetch="onFetchOrders"
        :filter-form="ordersForm"
        :thead="ordersHead"
        table-height="60vh"
      >
        <template #filter="{ pageData, idList }">
          <SourceScreen
            :openType="[]"
            :openCategory="[]"
            :openStatus="openStatus"
            :filterForm="ordersForm"
            :selectArr="idList"
            :page="pageData.current_page"
            :uploadFilter="ordersOk"
          >
          </SourceScreen>
        </template>
        <template #item_image_name="{ row }">
          <div class="flex">
            <list-image style="flex-shrink: 0;" :src="row.image" :width="100"></list-image>
            <p style="white-space: normal; margin-left: 10px">{{ row.name }}</p>
          </div>
        </template>
        <template #item_price_amount="{ row }">
          <div class="flex-col">
            <p style="white-space: normal">{{ row.price.toFixed(2) }}元</p>
            <p style="white-space: normal">x{{ row.amount }}</p>
          </div>
        </template>
        <template #item_total_price="{ row }">
          <div class="flex-col">
            <p style="white-space: normal">
              {{ row.total_price.toFixed(2) }}元
            </p>
          </div>
        </template>
        <template #item_retail_price="{ row }">
          <div class="flex-col">
            <p style="white-space: normal">
              {{ row.retail_price.toFixed(2) }}元
            </p>
          </div>
        </template>
      </ListLayout>
    </el-dialog>
    <el-dialog
      title="提现记录"
      width="800px"
      class="dialog-vertical"
      :visible.sync="showWithdraw"
      append-to-body
      close-on-click-modal
      close-on-press-escape
    >
      <ListLayout
        ref="withdrawList"
        :on-fetch="onFetchWithdraw"
        :filter-form="withdrawForm"
        :thead="withdrawHead"
        table-height="40vh"
      >
        <template #filter="{ pageData, idList }">
          <UserWithdrawRecordFilter
            :openStatus="openStatus2"
            :filterForm="withdrawForm"
            :selectArr="idList"
            :page="pageData.current_page"
            :uploadFilter="withdrawsOk"
          ></UserWithdrawRecordFilter>
        </template>

        <template #item_price="{ row }">
          <div class="flex-col">
            <p style="white-space: normal">{{ row.price.toFixed(2) }}元</p>
          </div>
        </template>
      </ListLayout>
    </el-dialog>
  </div>
</template>

<script>
import SourceScreen from '@/modules/activity/components/CycActivityOrder/SourceScreen'

import ListLayout from '@/base/layout/ListLayout'
import ListImage from '@/base/components/List/ListImage'
import ListFilter from '../../shop/components/ShopList/ListFilter.vue'
import ListStatusText from '../../../base/components/List/ListStatusText'
import DistributionFilter from '../components/DistributionFilter'
import {
  getRetailRecords,
  getWithdraws,
  userDistributionOrders,
} from '../api/record'
import UserWithdrawRecordFilter from '../components/UserWithdrawRecordFilter'

export default {
  components: {
    UserWithdrawRecordFilter,
    DistributionFilter,
    ListStatusText,
    ListFilter,
    ListImage,
    ListLayout,
    SourceScreen,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        page_size: 15,
        order_by: 'id', //id,income,withdraw,balance,orders_price,orders_count,withdraw_record_count
        is_desc: 1,
        goods_name: '',
        user_name: '',
        phone: '',
        min_orders_count: -1,
        max_orders_count: -1,
        min_orders_price: -1,
        max_orders_price: -1,
        min_retail_price: -1,
        max_retail_price: -1,
        export_ids: [],
        positionIds: [],
      },
      /**
       *             {
                "id": "aLJ27nmD",
                "income": 0,
                "withdraw": 0,
                "balance": 0,
                "orders_price": 0,
                "orders_count": 0,
                "withdraw_record_count": 0,
                "user": {
                    "id": "JDbvKYkp",
                    "nickname": "冯",
                    "avatar": "https:\/\/testsaasres.shetuan.pro\/cos\/qXV4g30AvmQRawYK\/images\/20210512\/4f79af584bf966ce49f0734343a25196.png",
                    "phone": "13428253684",
                    "title_name": "广州茂青会|会员",
                    "login_type": 0
                }
            }
       */
      thead: [
        {
          label: '会员ID',
          prop: 'user.id',
          minWidth: 100,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '会员名称',
          prop: 'user.nickname',
          minWidth: 100,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '手机号码',
          prop: 'user.phone',
          minWidth: 120,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '会内职务',
          prop: 'user.title_name',
          minWidth: 200,
          tooltip: false,
          type: 'slot',
        },
        {
          label: '分销订单数量',
          prop: 'orders_count',
          minWidth: 140,
          sortable: true,
        },
        {
          label: '分销订单金额',
          prop: 'orders_price',
          minWidth: 140,
          sortable: true,
        },
        {
          label: '佣金总金额',
          prop: 'income',
          minWidth: 140,
          sortable: true,
        },
        {
          label: '已提现金额',
          prop: 'withdraw',
          minWidth: 140,
          sortable: true,
        },
        {
          label: '提现成功次数',
          prop: 'withdraw_record_count',
          minWidth: 140,
          sortable: true,
        },
        {
          label: '未提现金额',
          prop: 'balance',
          minWidth: 140,
          sortable: true,
        },
        {
          label: '操作',
          type: 'operation',
          width: 160,
          align: 'right',
          operations: [
            {
              command: 'detail',
              text: '订单详情',
            },
            {
              command: 'withdraw_list',
              text: '提现记录',
            },
          ],
        },
      ],

      /* 查看详情 */
      showTransfer: false,
      /**
       *   {
                "id": "x605A807",
                "image": "https:\/\/testsaasres.shetuan.pro\/cos\/qXV4g30AvmQRawYK\/images\/20230106\/55e8a59aa75ea14ffeb9e055a5ccb16d.png",
                "name": "华为古董机",
                "retail_price": 111,
                "price": 200,
                "amount": 1,
                "total_price": 200,
                "orderids": "274364029955140424",
                "status_text": "已支付",
                "user_name": "麻辣兔头"
            }
       */
      ordersHead: [
        {
          prop: 'orderids',
          label: '订单号',
          minWidth: 180,
        },
        {
          prop: 'image_name',
          label: '商品名称',
          minWidth: 300,
          type: 'slot',
        },
        {
          prop: 'price_amount',
          label: '单价/数量',
          minWidth: 100,
          type: 'slot',
        },
        {
          prop: 'total_price',
          label: '实收款',
          minWidth: 100,
          type: 'slot',
        },
        {
          prop: 'retail_price',
          label: '佣金',
          minWidth: 100,
          type: 'slot',
        },
        {
          prop: 'status_text',
          label: '订单状态',
          minWidth: 100,
        },
        {
          prop: 'create_time',
          label: '下单时间',
          minWidth: 180,
        },
        {
          prop: 'user_name',
          label: '买家',
          minWidth: 100,
        },
      ],
      ordersForm: {
        user_id: '',
        page: 1,
        page_size: 15,
        goods_name: '',
        orderid: '',
        status: -1, //订单状态0：已支付，1：未支付，2：已取消，3：已退款
        start_time: -1,
        end_time: -1,
      },
      openStatus: [
        {
          name: '已支付',
          id: 0,
        },
        {
          name: '未支付',
          id: 1,
        },
        {
          name: '已取消',
          id: 2,
        },
        {
          name: '已退款',
          id: 3,
        },
        // {
        //   name: "退款中",
        //   id: 4,
        // },
      ],

      /* 提现记录 */
      showWithdraw: false,
      withdrawForm: {
        page: 1,
        page_size: 15,
        record_number: '',
        status: -1, // 状态,0：待审核，1：通过，2：不通过
        start_time: -1,
        end_time: -1,
        user_id: '',
      },
      // 0：待审核，1：通过，2：不通过
      openStatus2: [
        {
          id: 0,
          name: '审核中',
        },
        {
          id: 1,
          name: '通过',
        },
        {
          id: 2,
          name: '不通过',
        },
      ],
      /**
       *   "id": "2j3yVYL1",
       "price": 111,
       "status": 0,
       "create_time": "2024-05-15 20:44:37",
       "record_number": "123456789012345678",
       "user": {
                    "id": "JDbvKYkp",
                    "nickname": "冯",
                    "avatar": "https://testsaasres.shetuan.pro/cos/qXV4g30AvmQRawYK/images/20210512/4f79af584bf966ce49f0734343a25196.png",
                    "phone": "13428253684",
                    "title_name": "广州茂青会|会员",
                    "login_type": 0
                },
       "status_text": "审核中"
       */
      withdrawHead: [
        {
          label: '提现编号',
          prop: 'record_number',
          minWidth: 200,
        },
        {
          prop: 'price',
          label: '提现金额',
          minWidth: 100,
          type: 'slot',
        },
        {
          prop: 'status_text',
          label: '提现状态',
          minWidth: 100,
        },
        {
          prop: 'create_time',
          label: '提现时间',
          minWidth: 180,
        },
      ],
    }
  },
  methods: {
    onCommand({ command, row }) {
      switch (command) {
        case 'detail':
          this.ordersForm.user_id = row.user_id || row.user?.id
          this.showTransfer = true
          this.ordersOk(this.ordersForm)
          break
        case 'withdraw_list':
          this.withdrawForm.user_id = row.user_id || row.user?.id
          this.showWithdraw = true
          this.withdrawsOk(this.withdrawForm)
          break
        default:
      }
    },
    getList(q) {
      return getRetailRecords(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 }
      this.$refs.distributionList.getList(pageData)
    },
    ordersOk(e) {
      this.ordersForm = { ...e, page: 1 }
      if (this.$refs.ordersList) this.$refs.ordersList.getList(this.ordersForm)
    },
    withdrawsOk(e) {
      this.withdrawForm = { ...e, page: 1 }
      if (this.$refs.withdrawList) this.$refs.withdrawList.getList(this.withdrawForm)
    },
    onFetchOrders(q) {
      return userDistributionOrders(q)
    },
    onFetchWithdraw(q) {
      return getWithdraws(q)
    },
  },
}
</script>

<style scoped></style>
